@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  .no-scrollbar {
    -ms-overflow-style: none;
    scrollbar-width: none;
  }

  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }
}

* {
  margin: 0;
  padding: 0;
  font-family: "Metropolis", -apple-system, BlinkMacSystemFont, "Helvetica Neue",
    Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
    "Segoe UI Symbol";
}

.slick-prev,
.slick-next {
  width: 25px !important;
  height: 28px !important;
  background: #ff5757 !important;
  border-radius: 50% !important;
}

.slick-prev {
  /* margin-left: 24px !important; */
  z-index: 1;
  left: 10px !important;
}

.slick-next {
  right: 18px !important;
}

.slick-initialized {
  margin-right: 34px !important;
}

.slick-prev:before,
.slick-next:before {
  font-family: monospace !important;
  font-size: 28px !important;
  color: #faf6f6 !important;
}

.subHeading {
  color: #ff5757;
  margin-bottom: 0.5rem;
  font-family: inherit;
  font-weight: 700;
  line-height: 1.2;
}

.subHeading::after {
  content: "";
  display: block;
  width: 25px;
  padding: 0.5rem 0 0 0;
  border-bottom: 2px solid #ff5757;
}

.footer a {
  color: #5c5757;
}

.footer a:hover {
  color: #ff5757;
}

.locations::before {
  content: "";
  position: absolute;
  height: 60px;
  width: 60px;
  background-color: transparent;
  border-radius: 50%;
  margin-left: -66px;
  top: 4px;
  box-shadow: 13px -21px 1px -16px #fff;
}

.locations::after {
  content: "";
  position: absolute;
  height: 60px;
  width: 60px;
  background-color: transparent;
  border-radius: 50%;
  margin-left: 86px;
  top: 4px;
  box-shadow: -13px -21px 1px -16px #fff;
}

.star-bar {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  color: #8d8b8b;
}

.bar {
  height: 3px;
  margin-right: 5px;
}

.star-bar:nth-child(5) .bar {
  background-color: #e74c3c;
}

.star-bar:nth-child(4) .bar {
  background-color: #e67e22;
}

.star-bar:nth-child(3) .bar {
  background-color: #f39c12;
}

.star-bar:nth-child(2) .bar {
  background-color: #b3ca42;
}

.star-bar:nth-child(1) .bar {
  background-color: #79b63a;
}

.thumbs-wrapper {
  position: absolute !important;
  bottom: 1px !important;
}

.control-dots {
  display: none !important;
}

.slide img {
  border-radius: 5px 5px 0px 0px !important;
}

.carousel-item {
  position: relative;
  width: 100%;
  height: 0;
  padding-top: 56.25%;
  overflow: hidden;
}

.carousel-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.thumb .carousel-image {
  position: relative;
}

.carousel .thumb {
  width: 80px;
  height: 50px;
}

.thumbs-wrapper .thumbs {
  max-width: 300px;
  overflow-x: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.thumbs-wrapper .thumbs::-webkit-scrollbar {
  display: none;
}

.decrease-height {
  height: 48px;
  width: 250px;
}

.react-calendar {
  line-height: 0.2em !important;
  border-radius: 5px;
  border: none !important;
  box-shadow: 0 0 14px 0 rgba(0, 0, 0, 0.16);
}

.react-calendar__navigation {
  height: 30px !important;
  margin-bottom: 0.5em !important;
}

.react-calendar__tile--active {
  background: #2b98f7 !important;
}

.ce-block__content {
  max-width: 100%;
}

#editorjs .ce-block__content {
  max-width: 650px;
}

#editorjs .codex-editor__redactor {
  padding-bottom: 300px !important;
}

.codex-editor__redactor {
  padding-bottom: 0px !important;
}

#editor a {
  color: #3595ff;
  text-decoration: none;
}

#editor a:hover {
  text-decoration: underline;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: bold !important;
}

h1 {
  font-size: 2em !important;
}

h2 {
  font-size: 1.75em !important;
}

h3 {
  font-size: 1.5em !important;
}

h4 {
  font-size: 1.25em !important;
}

h5 {
  font-size: 1em !important;
}

h6 {
  font-size: 0.875em !important;
}
/* custom height for side bar and main content */
.custom-height-sidebar {
  height: calc(100vh - 168px); /* 100vh minus the height of the header */
}
.custom-height-content {
  height: calc(100vh - 56px);
}
/* custom height */
@media print {
  /* Common styles for both paper sizes */
  .invoice-container {
    width: 100%;
    padding: 10px;
    font-size: 10px;
    line-height: 1.4;
  }

  @media print and (min-width: 58mm) {
    /* Styles for 58mm paper */
    .invoice-container {
      width: 58mm;
    }
  }

  @media print and (min-width: 80mm) {
    /* Styles for 80mm paper */
    .invoice-container {
      width: 80mm;
    }
  }
}
